.rct-xy-plot * {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.rct-xy-plot .rct-chart-tick {
  fill: none;
  stroke: #666;
  stroke-width: 1px;
}
.rct-xy-plot .rct-chart-background {
  fill: none;
}
.rct-xy-plot .rct-plot-background {
  fill: #e0e0e0;
}
.rct-xy-plot .rct-chart-grid-line {
  stroke: #b9b9b9;
  stroke-width: 1px;
}
.rct-chart-line-x,
.rct-chart-line-y {
  stroke: #090909;
}
.rct-chart-bar,
.rct-chart-area-bar {
  fill: steelblue;
}
.rct-marker-line-chart line {
  stroke: black;
  stroke-width: 2px;
}
.rct-pie-chart .rct-pie-slice {
  stroke: none;
}
.rct-pie-chart .rct-pie-slice-0 {
  fill: steelblue;
}
.rct-pie-chart .rct-pie-slice-1 {
  fill: darkred;
}
.rct-pie-chart .rct-pie-slice-2 {
  fill: orange;
}
.rct-pie-chart .rct-pie-slice-empty {
  fill: #ddd;
}
.rct-pie-chart .rct-pie-label-center {
  font-size: 26pt;
}
.rct-pie-chart .rct-marker-line {
  stroke: black;
  stroke-width: 2px;
}
.rct-line-chart .rct-line-path {
  fill: none;
  stroke: #0a0a0a;
  stroke-width: 1.5px;
  stroke-linejoin: bevel;
}
.rct-area-chart .rct-area-chart-path {
  stroke-width: 0;
  fill: steelblue;
}
.rct-area-chart--difference .rct-area-chart-path {
  stroke: #0a0a0a;
  stroke-width: 1.5px;
  stroke-linejoin: bevel;
}
.rct-sankey-diagram .rct-sankey-nodes .rct-sankey-node {
  fill: #000;
}
.rct-sankey-diagram .rct-sankey-links .rct-sankey-link {
  stroke: rgba(0, 0, 0, 0.16);
  fill: none;
}
.rct-sankey-diagram .rct-sankey-node-label {
  pointer-events: none;
  font-size: 10pt;
}
.rct-sankey-diagram .rct-sankey-link-label {
  pointer-events: none;
  alignment-baseline: middle;
  dominant-baseline: central;
  font-size: 8.5pt;
  color: #333;
}
.rct-sankey-diagram .rct-sankey-node-terminal {
  fill: steelblue;
}
.rct-chart-axis-line-y,
.rct-chart-axis-line-x {
  stroke: #b9b9b9;
}
